/* eslint-disable react/prop-types */
import React from 'react';

import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
} from 'react-share';

const ShareButtons = ({ title, url, twitterHandle, tags }) => (
	<div style={{ display: 'flex', justifyContent: 'center' }}>
		<FacebookShareButton url={url}>
			<FacebookIcon style={{ marginRight: 8 }} size={40} round />
		</FacebookShareButton>

		<TwitterShareButton
			url={url}
			title={title}
			via={twitterHandle}
			hashtags={tags}
		>
			<TwitterIcon style={{ marginRight: 8 }} size={40} round />
		</TwitterShareButton>
	</div>
);
export default ShareButtons;
