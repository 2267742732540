/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({ url, title, description, image, article }) => {
	//   const { pathname } = useLocation()

	const seo = {
		title: title,
		description: description,
		image: image,
		url: url,
	};

	return (
		<Helmet title={seo.title}>
			<html lang="en-US" />
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link
				href="https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap"
				rel="stylesheet"
			/>
			<link rel="alternate" href={seo.url} hrefLang="en-us" />
			<link rel="alternate" href={seo.url} hrefLang="en" />
			<link rel="alternate" href={seo.url} hrefLang="x-default" />
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />

			{seo.url && <meta property="og:url" content={seo.url} />}

			{(article ? true : null) && <meta property="og:type" content="article" />}

			{seo.title && <meta property="og:title" content={seo.title} />}

			{seo.description && (
				<meta property="og:description" content={seo.description} />
			)}

			{seo.image && <meta property="og:image" content={seo.image} />}

			<meta name="twitter:card" content="summary_large_image" />

			{/* {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )} */}

			{seo.title && <meta name="twitter:title" content={seo.title} />}

			{seo.description && (
				<meta name="twitter:description" content={seo.description} />
			)}

			{seo.image && <meta name="twitter:image" content={seo.image} />}
		</Helmet>
	);
};

export default SEO;

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	article: PropTypes.bool,
};

SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	article: false,
};
