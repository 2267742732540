import React from "react"
import { graphql, Link } from "gatsby"
// import MyHelmet from '../components/MyHelmet';
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid"
import Image from "../components/Image"
import Content from "../components/Content"
import Seo from "../components/Seo"
import ShareButtons from "../components/Share"

import { ProjectHeader } from "../components/Header"
import { ContainerSmaller, Container } from "../components/Sections"

function ProductItemTemplate({
  title,
  location,
  date,
  image,
  body,
  showDate = true,
}) {
  const url = typeof window !== "undefined" ? window.location.href : ""
  return (
    <>
      <ProjectHeader
        showDate={showDate}
        heading={title}
        location={location}
        date={date}
      />
      <ContainerSmaller className="text-center -mt-24 sm:-mt-28 lg:-mt-36">
        <Image className="rounded-md" image={image} alt={image?.alt || ""} />
      </ContainerSmaller>
      <Content html={body} className="-mt-20" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            fontSize: "9pt",
            lineHeight: "31px",
            textAlign: "center",
            fontWeight: 200,
          }}
        >
          <p>Share This Product</p>
          <ShareButtons
            url={url}
            tags={null}
            title={title}
            twitterHandle="@HucknallTimber"
          />
        </div>
      </div>
    </>
  )
}

const ProductItem = ({ data }) => {
  const fm = data.directus.products[0]
  const { allSlugs } = data.directus
  const p = allSlugs.findIndex(i => i.id === fm.id)
  let nextUrl = null
  let prevUrl = null
  let nextTitle = null
  let prevTitle = null
  if (p < allSlugs.length - 1) {
    nextUrl = `/${allSlugs[p + 1].slug}`
    nextTitle = allSlugs[p + 1].title
  }
  if (p > 0) {
    prevUrl = `/${allSlugs[p - 1].slug}`
    prevTitle = allSlugs[p - 1].title
  }
  const imageURL = fm.featuredImage?.id
    ? `https://ht.clockworkbee.co.uk/assets/${fm.featuredImage.id}`
    : null

  return (
    <>
      <Seo title={fm.title} description={fm.subTitle} image={imageURL} />

      {/* <MyHelmet title={fm.title} description={fm.body} /> */}
      <ProductItemTemplate
        showDate={false}
        location={fm.title}
        date={fm.date}
        title={fm.subTitle}
        image={fm.featuredImage}
        body={fm.body}
      />

      {/* Links to previous and next project */}
      <Container>
        <div className="sm:flex sm:justify-between sm:items-center sm:gap-4 border-t py-4">
          {nextUrl ? (
            <div className="mt-6 sm:mt-0">
              <Link to={nextUrl} className="group sm:text-right">
                <div className="flex items-center gap-x-2 text-gray-500 sm:justify-end">
                  Previous
                  <ArrowNarrowLeftIcon className="w-5 h-5" />
                </div>
                <h3 className="font-bold text-lg text-gray-700 group-hover:underline">
                  {nextTitle}
                </h3>
              </Link>
            </div>
          ) : (
            <div />
          )}
          {prevUrl ? (
            <Link to={prevUrl} className="group">
              <div className="flex items-center gap-x-2 text-gray-500">
                <ArrowNarrowRightIcon className="w-5 h-5" />
                Next
              </div>
              <h3 className="font-bold text-lg text-gray-700 group-hover:underline">
                {prevTitle}
              </h3>
            </Link>
          ) : (
            <div />
          )}
        </div>
      </Container>
    </>
  )
}

export default ProductItem

export const productQuery = graphql`
  query productQ($slug: String!) {
    directus {
      products(filter: { slug: { _eq: $slug } }) {
        id
        title
        date
        pinned
        slug
        subTitle
        featuredImage {
          id
        }
        body
      }
      allSlugs: products(sort: ["pinned", "-date"]) {
        id
        slug
        title
      }
    }
  }
`
